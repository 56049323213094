<template>
  <div class="meterage-list-display">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select v-model="tenderGuid" placeholder="标段" clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="meterageList"
      border
      default-expand-all
      :height="tableHeight"
      row-key="meterageId"
      header-cell-class-name="bg-info text-light"
      >
      <el-table-column
        type="index"
        width="50"
        header-align="center"
        align="center"
        label="序号"
        >
      </el-table-column>
      <el-table-column
        prop="meterageDisplayCode"
        label="子目号"
        width="300"
        >
      </el-table-column>
      <el-table-column
        prop="meterageName"
        label="子目名称"
        width="400"
        >
      </el-table-column>
      <el-table-column
        prop="unit"
        label="单位"
        header-align="center"
        align="center"
        width="150"
        >
      </el-table-column>
      <el-table-column
        prop="pictureNum"
        label="合同数量"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column
        prop="price"
        label="合同单价"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column
        prop="pictureAmount"
        label="合同金额"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column
        prop="changeDirectiveNum"
        label="变更数量"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column
        prop="changeDirectivePrice"
        label="变更单价"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column
        prop="changeDirectiveAmount"
        label="变更金额"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column header-align="center" align="left" label="计量进度" width="100">
        <template  slot-scope="scope">
          <el-tooltip class="item" effect="light" :content="`计量完成：${Math.round(1000 * scope.row.declareNum / scope.row.pictureNum, 0) / 10.0}%`" :open-delay="500" placement="top">
            <el-progress v-if="scope.row.price" :show-text="false" :percentage="Math.round(100 * scope.row.declareNum / scope.row.pictureNum, 0)" :color="customColors"></el-progress>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="变更金额"
        header-align="center"
        align="center"
        :formatter="diffFormatter"
        >
      </el-table-column>
      -->
    </el-table>
  </div>
</template>

<script>
import tenderService from '@/services/tenderService'
import meterageListService from '@/services/meterageListService'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import auth from '@/common/auth'
import PageOperation from '@/mixins/PageOperation'
import utility from '@/common/utility'

export default {
  name: 'MeterageListDisplay',
  mixins: [
    PageOperation
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      tenders: [],
      tenderGuid: '',
      meterageList: [],
      tableHeight: null,
      customColors: [
        {
          color: '#f56c6c',
          percentage: 20
        },
        {
          color: '#1989fa',
          percentage: 40
        },
        {
          color: '#6f7ad3',
          percentage: 60
        },
        {
          color: '#e6a23c',
          percentage: 80
        },
        {
          color: '#5cb87a',
          percentage: 100
        }
      ]
    }
  },
  watch: {
    tenderGuid (newVal) {
      newVal && this._getMeterageList(newVal)
    }
  },
  methods: {
    numberFormatter (row, column) {
      return row[column.property] === 0 ? '' : row[column.property]
    },
    diffFormatter (row) {
      let diff = utility.floatSubstract(row.amount, Math.round(row.num * row.price))
      return row.amount ? diff : ''
    },
    _getMeterageList (tenderGuid) {
      meterageListService.allTreeList(tenderGuid)
        .then(res => {
          console.log(res.data)
          this.meterageList = res.data.data
          this.$message({
            type: 'success',
            message: '列表加载成功！'
          })
        })
        .catch(err => {
          console.log(err.request.response)
        })
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    }
  },
  created () {
    this._getTenders()
    this.tableHeight = document.body.clientHeight - 150
  }

}
</script>

<style scoped lang="scss">
.meterage-list-display {
  height: 100%;
}

::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .el-progress-bar {
  cursor: pointer;
}
</style>
